import { defineStore } from "pinia";
import { computed, ref, shallowRef } from "vue";
import { useRoute } from "vue-router";

import { GlobalSettingsApi, type ISettingRow, type ISetting } from "..";
import { ERoutesName } from "@/app/providers";

export const useGlobalSettingStore = defineStore("globalSettings", () => {
    const route = useRoute();

    const settings = ref<ISettingRow[]>([]);

    const isDisabledTeamGroupAccess = shallowRef<boolean>(true); // Custom feature flag
    const isDisabledSwitchDiagramTabs = shallowRef<boolean>(false); // Custom feature flag
    const isDisabledScoringOnSideBar = shallowRef<boolean>(false); // Custom feature flag
    const isDisabledDiagramLinks = shallowRef<boolean>(false); // Custom feature flag
    const isDisabledApprovalsSideBar = shallowRef<boolean>(true); // Custom feature flag

    const isDiagramPage = computed(() =>
        [ERoutesName.APP_DIAGRAM_ITEM, ERoutesName.APP_BCM_DIAGRAM_ITEM].includes(route.name as ERoutesName),
    );

    async function fetchAll(heartbeatAcknowledged: boolean = false): Promise<void> {
        settings.value = await GlobalSettingsApi.fetchAll(heartbeatAcknowledged);
    }
    function findSetting(name: string): ISetting {
        const finded = settings.value.find((item: ISettingRow) => item.name === name);

        const status: boolean = !!finded ? finded.value === "true" : false;

        return {
            status,
            value: finded?.value || null,
            data: finded,
        };
    }

    return {
        settings,
        fetchAll,
        findSetting,

        isDisabledTeamGroupAccess,
        isDisabledSwitchDiagramTabs,
        isDisabledScoringOnSideBar,
        isDisabledDiagramLinks,
        isDisabledApprovalsSideBar,

        isDiagramPage,
    };
});
