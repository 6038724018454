import { useSessionStore } from "@/entities/Session";
import { watch, ref } from "vue";
import { storeToRefs } from "pinia";
import { useLocalStorage } from "@/shared/lib/browser";
import { ANONYMOUS_ID_KEY } from "@/shared/config";

declare global {
    interface Window {
      umami?: any;
    }
  }
  

export function useUmami() {
    const sessionStore = useSessionStore();
    const { isAuth, userData } = storeToRefs(sessionStore);

    // Используем `useLocalStorage`, но обновляем `anonymousId` при изменении
    const anonymousId = ref<string>(useLocalStorage<string>(ANONYMOUS_ID_KEY).value);

    // Предупреждение, если `umami` не загружен
    function warnIfUmamiNotLoaded() {
        if (!window.umami) {
            console.warn("[Umami] Внимание: Umami не загружен. Вызовите `setupUmami()` снаружи перед использованием!");
        }
    }

    // Отслеживаем авторизацию, но не загружаем Umami внутри
    watch(
        isAuth,
        async (value: boolean) => {
            warnIfUmamiNotLoaded();
            if (value) {
                await identify(userData.value, anonymousId.value);
            } else {
                await resetIdentify();
            }
        },
        { flush: "post" },
    );

    async function setupUmami(): Promise<void> {
        if (window.umami) return; // Если уже загружен — выходим

        const umamiScript = document.createElement("script");
        const BASE_URL = "https://matomo.stormbpmn.com";
        umamiScript.src = `${BASE_URL}/script.js`;
        umamiScript.setAttribute("data-website-id", "584ccfd4-4446-40d4-b39c-85adfe482ede");
        umamiScript.setAttribute("data-auto-track", "true");
        umamiScript.defer = true;
        umamiScript.async = true;
        document.head.appendChild(umamiScript);

        return new Promise<void>((resolve, reject) => {
            umamiScript.onload = () => resolve(console.log("[Umami] Загружено"));
            umamiScript.onerror = () => reject(new Error("Не удалось загрузить скрипт Umami"));
            setTimeout(() => reject(new Error("Истекло время ожидания загрузки Umami")), 5000);
        }).catch(err => console.error(err.message));
    }

    async function trackCustomEvent(eventName: string, payload?: Record<string, unknown>): Promise<void> {
        warnIfUmamiNotLoaded();
        try {
            eventName = eventName.substring(0, 40);
            if (window.umami) {
                payload ? window.umami.track(eventName, payload) : window.umami.track(eventName);
            }
        } catch (error) {
            console.error("Ошибка в trackCustomEvent:", error);
        }
    }

    async function identify(userData?: Record<string, any>, anonId?: string): Promise<void> {
        warnIfUmamiNotLoaded();
        try {
            if (window.umami && userData) {
                window.umami.identify({
                    email: userData.username || "",
                    plan: userData.plan || "",
                    teamId: userData.teamId || "",
                    teamName: userData.teamName || "",
                    validTo: userData.validTo || "",
                    anonId: anonId || "",
                });
            }
        } catch (error) {
            console.error("Ошибка в identify:", error);
        }
    }

    async function resetIdentify(): Promise<void> {
        warnIfUmamiNotLoaded();
        try {
            if (window.umami) {
                window.umami.identify({});
            }
        } catch (error) {
            console.error("Ошибка в resetIdentify:", error);
        }
    }

    return {
        setupUmami,
        trackCustomEvent,
    };
}
