import { useUmami } from "./umamiTracker";

type Nullable<T> = T | null;

let umamiInstance: ReturnType<typeof useUmami> | null = null;
let umamiInitialized = false; // Флаг для контроля инициализации

export function useTracking() {
    const idCache = new WeakMap<HTMLElement, string>();
    const tooltipCache = new WeakMap<HTMLElement, string>();

    let lastTooltip: string = "";
    let lastTooltipTime: number = 0;

    const clickHandler = ({ target }: Event) => {
        if (!(target instanceof HTMLElement) || target.closest("[data-no-metrika]")) return;

        const getVueComponentName = (el: HTMLElement): string =>
            (el as any).__vue__?.type?.name || (el as any).__vueParentComponent?.type?.name || "uc";

        const getElementLabel = (el: HTMLElement): string =>
            el.textContent?.trim() ||
            el.getAttribute("aria-label") ||
            el.getAttribute("title") ||
            el.getAttribute("placeholder") ||
            tooltipCache.get(el) ||
            "ul";

        const getNearbyLabel = (el: HTMLElement): Nullable<string> =>
            el.closest("div")?.querySelector("label, b, span")?.textContent?.trim() || null;

        const computeLabel = (el: HTMLElement): string => {
            const label = (
                Date.now() - lastTooltipTime <= 2000 ? lastTooltip : getElementLabel(el) || getNearbyLabel(el) || "ul"
            )
                .replace(/\d+$/, "")
                .trim()
                .slice(0, 30);

            return `__${label}__`;
        };

        const generateStableLabel = (el: HTMLElement): string => {
            if (idCache.has(el)) return idCache.get(el)!;

            const label = `${getVueComponentName(el)}-${computeLabel(el)}`;
            idCache.set(el, label);
            return label;
        };

        const stableId = generateStableLabel(target);
        target.dataset.id = stableId;

        umamiInstance?.trackCustomEvent(stableId);
    };

    const observer = new MutationObserver(mutations => {
        const processMutations = () => {
            for (const mutation of mutations) {
                for (const node of mutation.addedNodes) {
                    if (node.nodeType === 1 && node instanceof HTMLElement && node.matches('[role="tooltip"], .tooltip, [data-tooltip]')) {
                        const tooltipText = node.textContent?.trim();
                        if (tooltipText && tooltipText !== lastTooltip) {
                            lastTooltip = tooltipText;
                            lastTooltipTime = Date.now();
                        }
                    }
                }
            }
        };

        requestIdleCallback(processMutations);
    });

    function startListen() {
        document.body.addEventListener("click", clickHandler, { passive: true });
        observer.observe(document.body, { childList: true, subtree: false });
    }

    function stopListen() {
        document.body.removeEventListener("click", clickHandler);
        observer.disconnect();
    }

    async function installation(enableListen: boolean) {

        if (!umamiInitialized) {
            umamiInitialized = true;
            umamiInstance = useUmami();
            await umamiInstance.setupUmami();
        }

        if (enableListen) {
            startListen();
        } else stopListen();
    }

    return { installation };
}
