import { useSessionStore } from "@/entities/Session";
import { useInterfaceStore } from "@/entities/Interface";
import { setTitlePage } from "@/shared/lib/browser";
import { ERoutesName } from ".";
import { formbricksRegisterRouteChange } from "@/shared/lib/formbricks";

import { type NavigationGuardNext } from "vue-router";

export const requiredAuth = (to: any, from: any, next: NavigationGuardNext) => {
    const sessionStore = useSessionStore();

    if (sessionStore.isAuth) {
        return next();
    }

    next({ name: ERoutesName.LOGIN, query: { redirectUrl: to.path } });
};
export const notRequiredAuth = (to: any, from: any, next: NavigationGuardNext) => {
    const sessionStore = useSessionStore();

    if (sessionStore.isAuth) {
        return next({ name: ERoutesName.APP_MY_PROCESSES });
    }

    next();
};
export const beforeEach = (to: any, from: any, next: NavigationGuardNext) => {
    const interfaceStore = useInterfaceStore();
    interfaceStore.clearSeo();

    next();
};

export const afterEach = async (to: any, from: any) => {
    const sessionStore = useSessionStore();

    const title = to.meta.title;

    if (title) setTitlePage(title);

    sessionStore.clickStream(to.fullPath);
    await formbricksRegisterRouteChange();
};

export const requiredFullAccess = (to: any, from: any, next: NavigationGuardNext) => {
    const sessionStore = useSessionStore();

    if (sessionStore.isFullAccess) {
        return next();
    }
    if (sessionStore.isAuth) {
        return next({ name: ERoutesName.APP_MY_PROCESSES });
    }

    next({ name: ERoutesName.LOGIN, query: { redirectUrl: to.path } });
};
